var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("labels.add")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{ref:"inputIndex_0",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('type'),"items":_vm.createTypes,"label":`${_vm.$t('labels.classify')} *`,"dense":"","outlined":"","hide-details":""},on:{"change":function($event){_vm.nextInputFocus(0);
              _vm.removeValidate('type');
              _vm.refreshCreateData();}},model:{value:(_vm.createData.type),callback:function ($$v) {_vm.$set(_vm.createData, "type", $$v)},expression:"createData.type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{ref:"inputIndex_1",staticClass:"c-input-small",attrs:{"disabled":_vm.isDisableInputDepartment,"error":_vm.validates.includes('department'),"items":_vm.createDepartments,"label":`${_vm.$t('labels.department')} *`,"dense":"","outlined":"","hide-details":""},on:{"change":function($event){_vm.nextInputFocus(1);
              _vm.removeValidate('department');}},model:{value:(_vm.createData.department),callback:function ($$v) {_vm.$set(_vm.createData, "department", $$v)},expression:"createData.department"}})],1),(_vm.createData.type)?[([_vm.config.TYPE.PICKUP_REDUCE].includes(_vm.createData.type))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_2",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('basket_code'),"label":`${_vm.$t('labels.basket_code')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(1)},"change":function($event){return _vm.removeValidate('basket_code')}},model:{value:(_vm.createData.basket_code),callback:function ($$v) {_vm.$set(_vm.createData, "basket_code", $$v)},expression:"createData.basket_code"}})],1):_vm._e(),([_vm.config.TYPE.PICKUP_REDUCE].includes(_vm.createData.type))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_3",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('cell_id'),"label":`${_vm.$t('labels.position')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(2)},"change":function($event){return _vm.removeValidate('cell_id')}},model:{value:(_vm.createData.cell_id),callback:function ($$v) {_vm.$set(_vm.createData, "cell_id", $$v)},expression:"createData.cell_id"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.RETURN_HAS_MONEY,
                _vm.config.TYPE.UPDATE_ORDER_STATUS,
                _vm.config.TYPE.RE_DELIVERY,
                _vm.config.TYPE.URGING_DELIVERY,
                _vm.config.TYPE.APPOINTMENT_DELIVERY,
                _vm.config.TYPE.CANCEL_DELIVERY,
                _vm.config.TYPE.INSPECTION_PACKING,
                _vm.config.TYPE.ORDER_CHECK,
                _vm.config.TYPE.ORDER_RETURN_CHECK,
                _vm.config.TYPE.ORDER_UPDATE_INFO,
                _vm.config.TYPE.ORDER_UPDATE_COD,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_4",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('order_tracking_id'),"label":`${_vm.$t('labels.tracking')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.nextInputFocus(3);
                _vm.getOrder();},"change":function($event){return _vm.removeValidate('order_tracking_id')},"blur":_vm.getOrder},model:{value:(_vm.createData.order_tracking_id),callback:function ($$v) {_vm.$set(_vm.createData, "order_tracking_id", $$v)},expression:"createData.order_tracking_id"}})],1):_vm._e(),([_vm.config.TYPE.ORDER_UPDATE_COD].includes(_vm.createData.type))?[_c('v-col',{attrs:{"cols":"6"}},[_c('input-number',{key:`ocod_${_vm.orderData.id}`,staticClass:"c-input-small",attrs:{"type":"number","outlined":"","dense":"","hide-details":"","label":`${_vm.$t('labels.old_cod')}`,"disabled":""},model:{value:(_vm.orderData.cash_on_delivery),callback:function ($$v) {_vm.$set(_vm.orderData, "cash_on_delivery", $$v)},expression:"orderData.cash_on_delivery"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('input-number',{key:`ncod_${_vm.orderData.id}`,ref:"inputIndex_5",staticClass:"c-input-small",attrs:{"type":"number","outlined":"","dense":"","hide-details":"","error":_vm.validates.includes('money'),"label":`${_vm.$t('labels.new_cod')} *`,"placeholder":"COD = 0 || > 10,000"},on:{"change":function($event){return _vm.removeValidate('money')},"input":_vm.addMessageCod},model:{value:(_vm.orderData.request_new_cod),callback:function ($$v) {_vm.$set(_vm.orderData, "request_new_cod", _vm._n($$v))},expression:"orderData.request_new_cod"}})],1)]:_vm._e(),(
              [
                _vm.config.TYPE.RETURN_HAS_MONEY,
                _vm.config.TYPE.SALARY_ADVANCE,
                _vm.config.TYPE.BONUS,
                _vm.config.TYPE.PUNISHMENT,
                _vm.config.TYPE.PRODUCT_REPAIR,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('input-number',{ref:"inputIndex_5",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('money'),"label":`${_vm.$t('labels.money_1')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(4)},"change":function($event){return _vm.removeValidate('money')}},model:{value:(_vm.createData.money),callback:function ($$v) {_vm.$set(_vm.createData, "money", _vm._n($$v))},expression:"createData.money"}})],1):_vm._e(),(
              [_vm.config.TYPE.BONUS, _vm.config.TYPE.PUNISHMENT].includes(
                _vm.createData.type
              )
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"inputIndex_6",staticClass:"c-input-small",attrs:{"items":_vm.employees,"label":`${_vm.$t('labels.select_employee')} *`,"dense":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.nextInputFocus(5)}},model:{value:(_vm.createData.id_identity_receipt),callback:function ($$v) {_vm.$set(_vm.createData, "id_identity_receipt", $$v)},expression:"createData.id_identity_receipt"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.RECEIPT_INCREASE,
                _vm.config.TYPE.RECEIPT_REDUCE,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_7",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('receipt_tracking_id'),"label":`${_vm.$t('labels.tracking_receipt')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(5)},"change":function($event){return _vm.removeValidate('receipt_tracking_id')}},model:{value:(_vm.createData.receipt_tracking_id),callback:function ($$v) {_vm.$set(_vm.createData, "receipt_tracking_id", $$v)},expression:"createData.receipt_tracking_id"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.GOODS_INCREASE,
                _vm.config.TYPE.GOODS_REDUCE,
                _vm.config.TYPE.PICKUP_REDUCE,
                _vm.config.TYPE.RECEIPT_INCREASE,
                _vm.config.TYPE.RECEIPT_REDUCE,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_8",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('sku'),"label":`${_vm.$t('labels.sku')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(6)},"change":function($event){return _vm.removeValidate('sku')}},model:{value:(_vm.createData.sku),callback:function ($$v) {_vm.$set(_vm.createData, "sku", $$v)},expression:"createData.sku"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.GOODS_INCREASE,
                _vm.config.TYPE.GOODS_REDUCE,
                _vm.config.TYPE.PICKUP_REDUCE,
                _vm.config.TYPE.RECEIPT_INCREASE,
                _vm.config.TYPE.RECEIPT_REDUCE,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"inputIndex_9",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('quantity'),"label":`${_vm.$t('labels.quantity')} *`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(7)},"change":function($event){return _vm.removeValidate('quantity')}},model:{value:(_vm.createData.quantity),callback:function ($$v) {_vm.$set(_vm.createData, "quantity", $$v)},expression:"createData.quantity"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.LEAVE,
                _vm.config.TYPE.TIMEKEEPING_ADDITIONAL,
                _vm.config.TYPE.LEAVE_EARLY,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"inputIndex_10",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('working_shift'),"items":_vm.workingShifts,"label":`${_vm.$t('labels.shift')} *`,"dense":"","outlined":"","hide-details":""},on:{"change":function($event){_vm.nextInputFocus(8);
                _vm.removeValidate('working_shift');}},model:{value:(_vm.createData.working_shift),callback:function ($$v) {_vm.$set(_vm.createData, "working_shift", $$v)},expression:"createData.working_shift"}})],1):_vm._e(),(
              [
                _vm.config.TYPE.LEAVE,
                _vm.config.TYPE.TIMEKEEPING_ADDITIONAL,
                _vm.config.TYPE.LEAVE_EARLY,
              ].includes(_vm.createData.type)
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('date-picker',{staticClass:"c-input-small",attrs:{"label":`${_vm.$t('labels.date')} *`,"name":"working_date","min-date":_vm.minDate(),"disabled":[
                  _vm.config.TYPE.TIMEKEEPING_ADDITIONAL,
                  _vm.config.TYPE.LEAVE_EARLY,
                ].includes(_vm.createData.type),"dense":"","outlined":"","hide-details":""},on:{"change":_vm.onWorkingDateChange}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"inputIndex_11",staticClass:"c-input-small",attrs:{"error":_vm.validates.includes('note'),"label":`${_vm.$t('labels.note')}`,"dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.nextInputFocus(9)},"change":function($event){return _vm.removeValidate('note')}},model:{value:(_vm.createData.note),callback:function ($$v) {_vm.$set(_vm.createData, "note", $$v)},expression:"createData.note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{staticClass:"c-input-small",attrs:{"accept":"image/*","append-icon":"mdi-paperclip","prepend-icon":"","error":_vm.validates.includes('file'),"dense":"","outlined":"","hide-details":""},on:{"change":_vm.onInputFileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)]:_vm._e()],2)],1),(_vm.errMsg)?_c('small',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errMsg))]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("labels.close"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveInternalRequest}},[_vm._v(" "+_vm._s(_vm.$t("labels.confirm"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }